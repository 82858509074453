import { MeasureSystemType } from 'types/commonInterfaces';

interface ITallUnit {
    IMPERIAL: 'in';
    METRIC: 'cm';
}

interface IWeightUnit {
    IMPERIAL: 'lbs';
    METRIC: 'kg';
}

interface IMeasureUnitSystem {
    [name: string]: MeasureSystemType;
}

export const MEASURE_SYSTEM: IMeasureUnitSystem = {
    lbs: 'IMPERIAL',
    kg: 'METRIC',
    in: 'IMPERIAL',
    cm: 'METRIC',
};

export const WEIGHT_UNIT: IWeightUnit = {
    IMPERIAL: 'lbs',
    METRIC: 'kg',
};

export const TALL_UNIT: ITallUnit = {
    IMPERIAL: 'in',
    METRIC: 'cm',
};

export const DATE_FORMATS = {
    DMY: 'DD/MM/YYYY',
    YMD: 'YYYY-MM-DD',
    MMM: 'MMM',
    MMMM: 'MMMM',
    DD: 'DD',
    YYYY: 'YYYY',
};

export const IMPERIAL = 'IMPERIAL';

export const METRIC = 'METRIC';

export const LBS = 'lbs';

export const KG = 'kg';

export const KILOS = 'kilos';
