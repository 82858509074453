import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectTestania = (state: RootState) => state.testania;

const selectTestaniaName = createSelector(selectTestania, (testania) => testania.testaniaName);
const selectCurrentBranchName = createSelector(selectTestania, (testania) => testania.currentBranchName);
const selectFlowLink = createSelector(selectTestania, (testania) => testania.flowLink);

export { selectTestania, selectTestaniaName, selectCurrentBranchName, selectFlowLink };
