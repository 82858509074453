import { ComponentType, LazyExoticComponent } from 'react';

export enum PreloadType {
    Init = 'init',
    NextPage = 'nextPage',
}

export type LazyLoadPropsType = {
    importer: () => Promise<{ default: ComponentType<any> }>;
    delayMs?: number;
    maxRetries?: number;
    onError?: (error: Error) => void;
    preloadType?: PreloadType;
};

export type LoadComponentFunction = <T extends ComponentType<any>>(props: LazyLoadPropsType) => LazyExoticComponent<T>;
