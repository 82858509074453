/* eslint-disable no-console */
// import PaymentApi from '../api';
import api from 'apiSingleton';
//
//
// const normalizeInitData = (data: any) => {
//   const underscoreKeysObj = toUnderScore(data);
//   switch (data.payment_type) {
//     case PaymentTypes.Lifetime: {
//       return lifetimeNormalize(underscoreKeysObj);
//     }
//     case PaymentTypes.Subscription: {
//       return subscriptionNormalize(underscoreKeysObj);
//     }
//     default:
//       return data;
//   }
// }

const initPaypal = async (
    // api: PaymentApi,
    payload: any
): Promise<any> => {
    // const normalizePayload = normalizeInitData(payload);
    const normalizePayload = payload;

    const responseInit = await api.payment.init(normalizePayload);

    if (responseInit) {
        // @ts-ignore
        const url = responseInit.pay_form?.script_url;

        if (!url) {
            throw new Error('URL for PayPal script is missing');
        }

        const buttonWrapperDiv = document.getElementById('paypal-button');

        if (!url || !buttonWrapperDiv) {
            throw Error('url && buttonWrapper required for init PayPal button');
        }

        insertScript(url);

        if (buttonWrapperDiv.getAttribute('listener') !== 'true') {
            console.log({ payload });
            activeHandlers(buttonWrapperDiv, payload);
        }
    }

    return responseInit;
};

function activeHandlers(buttonWrapperDiv: HTMLElement, { successHandler }: any) {
    if (!buttonWrapperDiv) {
        console.warn('buttonWrapperDiv is not defined');

        return;
    }

    // @ts-ignore
    onClick(buttonWrapperDiv, (e) => console.log('=====>>>>>>> ', e));
    buttonErrorHandler(buttonWrapperDiv, (e) => console.log('=====>>>>>>> ', e));
    // @ts-ignore
    buttonReadyHandler(buttonWrapperDiv, (e) => console.log('=====>>>>>>> ', e));
    orderProcessedHandler(buttonWrapperDiv, successHandler);

    buttonWrapperDiv.setAttribute('listener', 'true');
}

function insertScript(url: string) {
    const { head } = document;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    script.setAttribute('data-script', ' ');
    script.setAttribute('data-btn-id', 'solid_pay_btn');
    script.setAttribute('data-overlay', 'true');
    script.setAttribute('data-tittle', 'Tittle for payment widget');
    script.setAttribute('data-description', 'widget.data-description');

    head.insertBefore(script, head.firstChild);
}

function buttonErrorHandler(buttonWrapperDiv: HTMLElement, callback: (error: Error) => void) {
    buttonWrapperDiv.addEventListener(
        'button-error',
        (e: any) => {
            console.error('button-error', e);
            callback(e);
        },
        false
    );
}

function buttonReadyHandler(buttonWrapperDiv: HTMLElement, callback = () => {}) {
    buttonWrapperDiv.addEventListener(
        'button-ready',
        (e: any) => {
            console.log('button-ready', e);
            callback();
        },
        false
    );
}

function orderProcessedHandler(buttonWrapperDiv: HTMLElement, callback: (data: any) => void) {
    buttonWrapperDiv.addEventListener(
        'order-processed',
        (e: any) => {
            callback(e.detail.data); //return order, pay_info
        },
        false
    );
}

function onClick(buttonWrapperDiv: HTMLElement, callback = () => {}) {
    buttonWrapperDiv.addEventListener(
        'order-started-processing',
        () => {
            callback();
        },
        false
    );
}

export default initPaypal;
