import { TestaniaStore } from 'types/testania/testaniaStore';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import { LS_KEYS } from 'helpers/localStorage';

import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: TestaniaStore = {
    onboardingConfig: [],
    testaniaName: null,
    testaniaLoginStatus: null,
    completeOBs: [],
    currentBranchName: null,
    flowLink: null,
};

const TestaniaReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_ONBOARDING_CONFIG:
            return { ...state, onboardingConfig: action.payload };

        case actionTypes.SET_TESTANIA_NAME:
            localStorage.setItem(LS_KEYS.testania_name, action.payload);

            return { ...state, testaniaName: action.payload };

        case actionTypes.SET_TESTANIA_LOGIN_STATUS:
            localStorage.setItem(LS_KEYS.loginStatus, `${action.payload}`);

            return { ...state, testaniaLoginStatus: action.payload };

        case actionTypes.SET_COMPLETE_OBS:
            return { ...state, completeOBs: [...state.completeOBs, action.payload] };

        case actionTypes.CLEAR_COMPLETE_OBS:
            return { ...state, completeOBs: [] };

        case actionTypes.SET_CURRENT_BRANCH_NAME:
            localStorage.setItem(LS_KEYS.currentBranchName, action.payload);

            return { ...state, currentBranchName: action.payload };

        case actionTypes.SET_FLOW_LINK:
            return { ...state, flowLink: action.payload };

        default:
            return state;
    }
};

export default TestaniaReducer;
