import { DEFAULT_HEADER_SETTINGS } from 'redux/redesignedFlow/constants';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import type { RedesignedFlowI } from 'types/redesignedFlow';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: RedesignedFlowI = {
    isRedesignedFlow: false,
    headerSettings: DEFAULT_HEADER_SETTINGS,
};

const redesignedFlow = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_IS_REDESIGNED_FLOW:
            return { ...state, isRedesignedFlow: action.payload };

        case actionTypes.SET_HEADER_SETTINGS:
            return { ...state, headerSettings: { ...action.payload } };

        default:
            return state;
    }
};

export default redesignedFlow;
