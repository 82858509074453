import { InitBankCard } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { takeLatest, put, all } from 'redux-saga/effects';

import * as actionTypes from 'redux/payment/actionTypes';
import { setBankCardSrc, setInitPaymentData, setLoadingStatus, setOrderId } from 'redux/payment/actions';

import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';

export function* init({ payload }: ActionType<InitBankCard>) {
    try {
        yield put(setLoadingStatus(true));
        const response: PaymentData = yield Solid.init('bankCard', payload);

        yield all([
            put(setBankCardSrc(response?.pay_form?.form_url)),
            put(setLoadingStatus(false)),
            put(setInitPaymentData(response)),
            put(setOrderId(response?.order?.order_id)),
        ]);
    } catch (e) {
        console.error('Error');
    }
}

export const initBankCard = [takeLatest(actionTypes.INIT_BANK_CARD, init)];
