import { Init } from 'testania';

import config from 'config';

import { DEFAULT_FLOW, DEFAULT_FLOW_PROJECT_NAME, DEFAULT_EXPIRE_CONFIG_TIME } from 'constants/defaultFlows';

import { getConfigBy, getVariantBranchName, checkFbBots } from './helpers';

export const TESTANIA_INIT_CONFIG: Init = {
    project: DEFAULT_FLOW_PROJECT_NAME,
    testName: getConfigBy('test-name', 'default'),
    branchName: getConfigBy('branch-name', 'default'),
    variantName: getVariantBranchName(),
    defaultBranches: ['default'],
    defaultFlow: DEFAULT_FLOW,
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    expireConfigTime: DEFAULT_EXPIRE_CONFIG_TIME,
    runOnlyLocalConfig: checkFbBots(),
};
