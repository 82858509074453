import { useTranslation } from 'react-i18next';

import { Box, Button, Image, Text } from 'libComponents';
import Container from 'components/Container';
import ContactUsLink from 'components/ContactUsLink';

import styles from './ErrorFallback.module.css';

import face_with_spiral_eyes from './img/face_with_spiral_eyes.webp';

export function ErrorFallback() {
    const { t } = useTranslation();

    return (
        <Container className={styles.errorWrapper}>
            <Box className={styles.errorContainer}>
                <Image center maxWidth={40} alt={t('basics.appError')} src={face_with_spiral_eyes} mb={20} />
                <Text type="h5" text={t('basics.appError')} bold center mb={12} />
                <Text color="text-secondary" text={t('basics.appError.subTitle')} center mb={32} />
                <Button text={t('basics.tryAgain')} onClick={() => window.location.reload()} mb={20} />
                <ContactUsLink subject={t('basics.appError')} text="basics.contactSupport" />
            </Box>
        </Container>
    );
}
