import { Main, uaParser } from 'wikr-core-analytics';
import { SENTRY_AXIOS } from 'sentry-utils';
import { Event } from '@sentry/react';

import { CSS_CHUNK_LOAD_FAILED, chunkError } from 'constants/lazyWithRetry';
import { IOS, ANDROID, CHROME, IOS_MIN_VERSION, ANDROID_MIN_VERSION, CHROME_MIN_VERSION } from 'constants/devices';

import { objectEntriesPolyfill } from 'helpers/polyfills';
import { isOnline } from 'helpers/environment';

import { Extras, Tags, IError } from './interfaces';
import { ErrorTypes } from './enums';

export const getCustomTags = (error: IError, type: keyof typeof ErrorTypes, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const userAgent = UAParser.setUA(event?.extra?.agent as string);

    const browser = userAgent?.getBrowser();
    const deviceOS = userAgent?.getOS();

    const parsedOSVersion = parseFloat(deviceOS?.version || '');
    const parsedBrowserVersion = parseFloat(browser?.major || '');

    return (
        (deviceOS?.name === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOS?.name === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browser?.name === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};

export const isChunkLoadHighLevelError = (error: Event) => {
    return error.exception?.values?.some(({ value }) => value?.includes(chunkError.loadingCSSChunkError));
};

const isChunkError = (event: Event) => {
    const isHighLevel = isChunkLoadHighLevelError(event);
    const isAppLevel =
        event?.message?.includes(chunkError.loadingChunkError) ||
        event?.message?.includes(chunkError.loadingCSSChunkError);

    return { isHighLevel, isAppLevel, hasError: isHighLevel || isAppLevel };
};

const getAnalyticsData = async () => {
    const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

    return (isInitAmazonAnalytic && (await Main?.getDefaultAmazonAnalyticPurchaseData?.())) || {};
};

export const setCustomTags = async (event: Event): Promise<Event> => {
    const customEvent = { ...event };
    const analyticData = await getAnalyticsData();

    if (customEvent) {
        customEvent.tags = { ...customEvent?.tags, IS_ONLINE: isOnline() };

        const { hasError, isHighLevel } = isChunkError(customEvent);

        if (hasError) {
            customEvent.tags = {
                ...customEvent?.tags,
                ERROR_TYPE: CSS_CHUNK_LOAD_FAILED,
            };

            if (isHighLevel) {
                customEvent.extra = { ...customEvent?.extra, ...analyticData };
            }

            console.error(CSS_CHUNK_LOAD_FAILED, customEvent);
        }
    }

    return customEvent;
};
