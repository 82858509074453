import config from 'config';

import * as action from './actions';

import * as actionTypes from './actionTypes';

import { UiEffectStore } from 'types/uiEffects/uiEffectStore';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UiEffectStore = {
    hide_header: null,
    modalIsOpen: false,
    issueType: 'ERROR',
    issueTitle: '',
    issueButtonTitle: '',
    issueDescription: '',
    appLoader: false,
    hideProgressBar: null,
    hidePayPal: !!JSON.parse(config?.HIDE_PAYPAL || 'false'),
    initFrontChat: null,
};

const UiEffects = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.HIDE_HEADER:
            return { ...state, hide_header: action.payload };

        case actionTypes.SHOW_MODAL: {
            return {
                ...state,
                modalIsOpen: action.payload?.status,
                issueDescription: action.payload?.description,
                issueTitle: action.payload?.title,
                issueButtonTitle: action.payload?.issueButtonTitle,
                issueType: 'ERROR',
            };
        }

        case actionTypes.HIDE_MODAL: {
            return {
                ...state,
                modalIsOpen: false,
                issueDescription: '',
                issueTitle: '',
                issueButtonTitle: null,
                issueType: null,
            };
        }

        case actionTypes.SET_LOADER_STATE: {
            return { ...state, appLoader: action.payload };
        }

        case actionTypes.HIDE_PROGRESS_BAR: {
            return { ...state, hideProgressBar: action.payload };
        }

        case actionTypes.HIDE_PAYPAL: {
            window.HIDE_PAYPAL = Number(action.payload);

            return { ...state, hidePayPal: action.payload };
        }

        case actionTypes.INIT_FRONT_CHAT:
            return { ...state, initFrontChat: action.payload };

        default:
            return state;
    }
};

export default UiEffects;
