import { UnitInput as LibSingleUnitInput, IUnitInputSingle } from 'wikr-core-components';
import { FC } from 'react';

const SingleUnitInput: FC<IUnitInputSingle> = ({
    placeholder,
    value,
    unit,
    hint,
    showHint,
    dataLocator,
    filled,
    onChange,
    onKeyDown,
    inputRef,
}) => {
    return (
        <LibSingleUnitInput
            unit={unit}
            placeholder={placeholder}
            value={value}
            hint={hint}
            showHint={showHint}
            dataLocator={dataLocator}
            filled={filled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            inputRef={inputRef}
        />
    );
};

export { SingleUnitInput };
