import { ActionEventAction, ElementEventAction, EventTrigger, Main, ITrackNew } from 'wikr-core-analytics';

import { PageType } from 'constants/analytics';

import { sendGTMPurchaseEvent } from 'services/Analytics/trackers/GTMtrakers';

import { changePathNameToScreenId } from '../helpers';

import { PurchaseData, PurchaseOption, TrackClickData } from '../types';

export const trackScreenLoad = (screenIdParam?: string) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackScreenLoadPopup = (screenIdParam?: string) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.popup,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackEventFixed = ({ eventData, data }: ITrackNew) => {
    Main.trackNew({
        eventData,
        ...(data && { data }),
    });
};

export const trackClick = ({ elementEvent, actionEvent, eventLabel, screenIdParam, data = {} }: TrackClickData) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(elementEvent && actionEvent && { actionData: { elementEvent, actionEvent } }),
        ...(eventLabel && { data: { event_label: eventLabel, ...data } }),
    });
};

export const getDefaultAmazonAnalyticPurchaseData = () => Main.getDefaultAmazonAnalyticPurchaseData();

export const purchase = (data: PurchaseData, pageType?: PageType, purchaseOptions?: PurchaseOption) => {
    Main.purchaseNew(data, purchaseOptions);
    sendGTMPurchaseEvent(data, pageType);
};

export const pendingPurchase = (data: PurchaseData, pageType?: PageType) => {
    Main.customData('purch_pending', data);
    sendGTMPurchaseEvent(data, pageType);
};

export const purchaseClick = (paymentValue: string) => {
    Main.customData('processor_form__click', {
        payment: paymentValue,
        event_label: paymentValue,
    });
};

export const trackGTM = Main.trackGTM.bind(Main);
