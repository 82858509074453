import { IMPERIAL, KG, LBS, METRIC } from 'constants/measureUnits';

import { getToFixedNumber } from 'helpers/utils';

import { MeasureSystemType } from 'types/commonInterfaces';

class Convert {
    private numberSystem: MeasureSystemType;
    private needConvertResult: boolean;

    constructor(numberSystem?: MeasureSystemType) {
        this.numberSystem = numberSystem || METRIC;
        this.needConvertResult = numberSystem === IMPERIAL;
    }

    fromLbsToKg(lbs: number) {
        return Math.round(lbs / 2.2046);
    }

    fromKgToLbs(kg: number, isRounded = true) {
        return isRounded ? Math.round(kg * 2.2046) : kg * 2.2046;
    }

    fromInToCm(inch: number) {
        return inch / 0.3937;
    }

    getBMI(weight: number, height: number) {
        return getToFixedNumber(weight / Math.pow(height / 100, 2));
    }

    getWeight(weight: number | string, isRounded = true) {
        const fixedWeight = isRounded ? Math.round(Number(weight)) : Number(weight);

        return this.needConvertResult ? this.fromKgToLbs(fixedWeight, isRounded) : fixedWeight;
    }

    getUnit() {
        return this.numberSystem === IMPERIAL ? LBS : KG;
    }

    fromFeetAndInchesToCentimeters(ft: string | number, inch: string | number) {
        const feetInCM = Number(ft) * 30.48;
        const inchesInCM = Number(inch) * 2.54;

        return getToFixedNumber(feetInCM + inchesInCM);
    }
}

export default Convert;
