import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectUser = (state: RootState) => state.user;

const selectEmail = createSelector(selectUser, (user) => user.email);
const selectOrderId = createSelector(selectUser, (user) => user.orderId);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectGuideId = createSelector(selectUser, (user) => user.guideId);
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectDeepLinks = createSelector(selectUser, (user) => user.deepLink);
const selectIsPersonalPlan = createSelector(selectUser, (user) => user.isPersonalPlan);

export {
    selectUser,
    selectEmail,
    selectOrderId,
    selectUserId,
    selectIsLoading,
    selectToken,
    selectCountry,
    selectGuideId,
    selectDeepLinks,
    selectIsPersonalPlan,
};
