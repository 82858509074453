/* eslint-disable no-console */
import { ERROR_LEVELS, SENTRY_PAYMENT, SOLID_ERROR_CODES, SOLID_ERROR_CODES_GROUPS } from 'sentry-utils';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { selectUser } from 'redux/user/selectors';
import { selectPayment } from 'redux/payment/selectors';
import {
    initPayPal as initPayPalAction,
    initPaymentGenerator as initPaymentGeneratorAction,
    setLoadingStatus,
    changePaymentMethod,
    setIsPaymentScreen,
    setCustomPaymentButton,
    setIsLoadingBankCard,
    setIsApplePayAvailable,
    setIsGooglePayAvailable,
    setMerchantData,
} from 'redux/payment/actions';

import { PAYMENT_EVENT_NAMES, PAYMENT_TYPES, SOLID__NOT_STANDARD_ERROR } from 'constants/payments';

import sentry from 'services/Sentry/SentryInstance';

import useSolidPaymentPreInit from 'hooks/useSolidPaymentPreInit';

import { Currency, PaymentMethod } from 'types/payments/payments';

import { DataForValidate } from 'interfaces/Payments/paymentSystemsInit';
import { useValidatePayment } from './useValidatePayment';
import { IUseProducts } from './useProducts';
import { purchaseClick, sendAnalyticProcessorFromLoad } from '../services/Analytics';

interface IUsePaymentLib extends IUseProducts {
    toNextPage: ({ isValid }: { isValid: boolean }) => void;
    hasCustomButton?: boolean;
}

interface IUsePaymentLibData {
    isLoading: boolean;
    paymentType: string;
    handleChangePaymentMethod: (type: PaymentMethod) => void;
    payment: {
        initPayPal: () => void;
        initPaymentGenerator: () => void;
    };
}

export function usePaymentLib({ pageInfo, toNextPage, hasCustomButton = true }: IUsePaymentLib): IUsePaymentLibData {
    const { user_id, country } = useSelector(selectUser);
    const { isLoading, currency, currentProduct: incomingCurrentProduct, validatePaymentData } = useSelector(
        selectPayment
    );

    const currentProduct = {
        ...incomingCurrentProduct,
        product_id: incomingCurrentProduct?.id,
    };

    const validatePayment = useValidatePayment();
    const dispatch = useDispatch();

    useSolidPaymentPreInit({ initPayPal, initPaymentGenerator });

    const paymentType = pageInfo.products[0].payment_type;

    useEffect(() => {
        dispatch(setMerchantData(null));

        if (validatePaymentData && Boolean(validatePaymentData?.result)) {
            toNextPage({
                isValid: validatePaymentData?.result,
            });
        }
    }, [validatePaymentData]);

    useEffect(() => {
        if (hasCustomButton) {
            dispatch(setCustomPaymentButton(true));

            return () => {
                dispatch(setCustomPaymentButton(false));
            };
        }
    }, []);

    useEffect((): (() => void) => {
        dispatch(setLoadingStatus(true));
        dispatch(setIsPaymentScreen(true));

        return () => dispatch(setIsPaymentScreen(false));
    }, []);

    const getCurrencyName = (currency: Currency): string => currency.name;

    const getErrorReasonGroup = (errorCode: string) => {
        let reason = SOLID__NOT_STANDARD_ERROR;

        SOLID_ERROR_CODES_GROUPS.forEach(({ name, errorList }) => {
            if (errorList.includes(errorCode)) {
                reason = name;
            }
        });

        return reason;
    };

    function initPayPal() {
        const meta = {
            userId: user_id,
            payment_method: PAYMENT_TYPES.PAYPAL,
            // @ts-ignore
            price: currentProduct?.fullPrice || 1,
            country,
            currency: getCurrencyName(currency),
            successHandler: (data: DataForValidate) => {
                const errorCode = data?.error?.code;

                if (SOLID_ERROR_CODES.includes(errorCode)) {
                    console.log('successHandler paypal error', data);

                    dispatch(setLoadingStatus(false));
                    initPayPal();

                    sentry.logError(
                        new Error('PayPal error'),
                        SENTRY_PAYMENT,
                        ERROR_LEVELS.CRITICAL,
                        {
                            ...currentProduct,
                            ...meta,
                        },
                        [
                            ['SOLID_ERROR_CODE', errorCode],
                            ['SOLID_ERROR_MESSAGE', data?.error?.recommended_message_for_user || ''],
                            ['SOLID_METHOD', data?.order?.method],
                            ['reason', getErrorReasonGroup(errorCode)],
                        ]
                    );
                } else {
                    dispatch(changePaymentMethod('PAYPAL'));

                    validatePayment('payPal', meta.payment_method, data);
                }
            },
            errorHandler: (e: Error) => {
                sentry.logError(e, SENTRY_PAYMENT, ERROR_LEVELS.ERROR, {
                    // @ts-ignore
                    ...currentProduct,
                    ...meta,
                });
                dispatch(setLoadingStatus(false));
            },
            onClickHandler: () => {
                dispatch(setLoadingStatus(true));

                purchaseClick(PAYMENT_EVENT_NAMES.paypal);
            },
            readyHandler: () => {
                dispatch(setLoadingStatus(false));

                sendAnalyticProcessorFromLoad({
                    payment: 'paypal',
                    event_label: 'paypal',
                });
            },
        };

        const payload = {
            ...currentProduct,
            ...meta,
        };

        // @ts-ignore
        dispatch(initPayPalAction(payload));
    }

    function initPaymentGenerator() {
        const metaBank = {
            userId: Number(user_id),
            country,
            currency: getCurrencyName(currency),
            payment_method: PAYMENT_TYPES.CREDIT_CARD,
        };

        dispatch(setIsLoadingBankCard(true));

        const payload = { ...currentProduct, ...metaBank };

        dispatch(setIsApplePayAvailable(false));
        dispatch(setIsGooglePayAvailable(false));

        // @ts-ignore
        dispatch(initPaymentGeneratorAction(payload));
    }

    const handleChangePaymentMethod = (type: PaymentMethod) => {
        console.log({type});
        dispatch(changePaymentMethod(type));
    };

    return {
        paymentType,
        isLoading,
        handleChangePaymentMethod,
        payment: {
            initPayPal,
            initPaymentGenerator,
        },
    };
}
