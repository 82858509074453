import { uaParser } from 'wikr-core-analytics';

import { ANDROID, IOS, MOBILE_SAFARI, SAFARI } from 'constants/devices';

const UAParser = new uaParser();

const getDeviceOs = () => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return getOSData?.name;
};

export const getBrowser = () => {
    const userAgent = window.navigator.userAgent;

    return UAParser.setUA(userAgent)?.getBrowser()?.name?.toUpperCase();
};

export const isIOS = getDeviceOs() === IOS;
export const isAndroid = getDeviceOs() === ANDROID;
export const isSafari = getBrowser() === SAFARI.toUpperCase() || getBrowser() === MOBILE_SAFARI.toUpperCase();
