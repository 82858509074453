import { OptionSize } from 'components/redesigned/types';

export const getTextType = (size: OptionSize) => {
    switch (size) {
        case OptionSize.Large:
            return 'medium-text';

        case OptionSize.ExtraLarge:
            return 'large-text';

        default:
            return 'small-text';
    }
};

export const getTextCenter = (size: OptionSize) => {
    switch (size) {
        case OptionSize.Small:

        case OptionSize.Medium:
            return true;

        default:
            return false;
    }
};
