import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments/currency';

import { getPriceFromCents } from 'helpers/utils';
import { getContentId } from 'helpers/payment/utils';

import { CurrentProduct } from 'types/payments/payments';

export const changePathNameToScreenId = (pathName = window.location.pathname) => {
    const screenId = pathName.replace(/^\/|\/$/g, '').replace(/(\/([a-z]{2})\/)|\//g, '_');

    return screenId;
};

export const getGTMDataFromCurrentProduct = (currentProduct: CurrentProduct | null, currency: string | undefined) => {
    if (!currentProduct) return {};

    const searchParams = new URLSearchParams(window.location.search);
    const startPrice = getPriceFromCents(currentProduct?.start_price);
    const price = getPriceFromCents(currentProduct?.price);

    return {
        url_params: Object.fromEntries(searchParams),
        price: startPrice || price,
        subscription_price: getPriceFromCents(currentProduct?.price) || 0,
        tariff: currentProduct?.id,
        currency: currency || PAYMENT_DEFAULT_CURRENCY.name,
        value: currentProduct?.ltv / 100,
        screen_id: changePathNameToScreenId(),
        content_id: getContentId({
            product: currentProduct,
            price: price,
            trialPrice: startPrice,
        }),
    };
};
