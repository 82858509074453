import { isPilatesBranch } from 'helpers/testania';

// We can distinguish between the old and new flows only by the testName which includes 'redesign__Test' fragment
import { LS_KEYS } from 'helpers/localStorage';

export const isRedesignedFlow = (testName: string) => testName.includes('redesign__Test');

export const getActivityLevel = () => {
    const branchName = localStorage.getItem(LS_KEYS.currentBranchName);
    const isPilates = isPilatesBranch(branchName);

    return `onboardingFlow.redesignedFlow.level.${isPilates ? 'pilates' : 'yoga'}Level--dynamicKey`;
};
