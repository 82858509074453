import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectUIEffects = (state: RootState) => state.uiEffects;

const selectHideHeader = createSelector(selectUIEffects, (uiEffects) => uiEffects.hide_header);
const selectHideProgressBar = createSelector(selectUIEffects, (uiEffects) => uiEffects.hideProgressBar);
const selectHidePayPal = createSelector(selectUIEffects, (uiEffects) => uiEffects.hidePayPal);
const selectInitFrontChat = createSelector(selectUIEffects, (uiEffects) => uiEffects.initFrontChat);

export { selectUIEffects, selectHideHeader, selectHideProgressBar, selectHidePayPal, selectInitFrontChat };
