import { EXCLUSIVE_OFFER_PRODUCT, PRICE_BY_COUNTRY } from 'constants/exclusiveOfferProduct';

import { CurrentProduct } from 'types/payments/payments';

export const getExclusiveOfferProductByCountry = (country: string): CurrentProduct => {
    return {
        ...EXCLUSIVE_OFFER_PRODUCT,
        ...(PRICE_BY_COUNTRY[country] ?? PRICE_BY_COUNTRY.default),
    };
};
