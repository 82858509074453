// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { validatePayment } from 'redux/payment/actions';

import { PaymentMethodType } from 'types/payments/validate';

export function useValidatePayment() {
    const dispatch = useDispatch();
    const history = useHistory();

    return (system: string, paymentMethod: PaymentMethodType, data: any) => {
        const payload = {
            system,
            paymentMethod,
            data,
            screenId: history.location.pathname,
        };

        dispatch(validatePayment(payload));
    };
}
