import { Box } from 'wikr-core-components';
import classNames from 'classnames/bind';

import { Text } from 'libComponents';
import LazyImage from 'components/LazyImage';

import styles from './PersonalFeedback.module.css';

import type { IPersonalFeedback } from './types';
import { PersonalFeedbackBgColor } from './types';

const cx = classNames.bind(styles);

function PersonalFeedback({
    image,
    icon,
    title,
    feedback,
    titleSlogan,
    sloganColor,
    bgColor = PersonalFeedbackBgColor.DEFAULT,
    className,
    sloganTitleClassName,
}: IPersonalFeedback) {
    const containerStyles = cx('container', className, {
        [bgColor]: Boolean(bgColor),
    });
    const sloganTitleStyles = cx('sloganDefault', sloganTitleClassName);

    return (
        <div className={containerStyles}>
            <div className={styles.imageWrapper}>
                {image && <LazyImage src={image} alt="feedback icon" />}
                {icon && icon}
            </div>
            <div>
                <Box mb={6}>
                    {titleSlogan && (
                        <>
                            <Text as="span" type="caption" bold mb={6} className={sloganTitleStyles}>
                                <span style={{ color: sloganColor }}>{titleSlogan}</span>
                            </Text>
                            <span>: </span>
                        </>
                    )}
                    <span className={styles.title}>{title}</span>
                </Box>
                <Text type="caption">{feedback}</Text>
            </div>
        </div>
    );
}

export default PersonalFeedback;
