export enum LS_KEYS {
    currentBranchName = 'currentBranchName',
    token = 'token',
    loginStatus = 'loginStatus',
    paidStatus = 'paidStatus',
    config = 'config',
    completedOBs = 'completedOBs',
    paymentType = 'paymentType',
    pageIsAlreadyReload = 'page-is-already-reload',
    persistRoot = 'persist:root',
    release_date = 'release_date',
    uuid = 'uuid',
    testania_name = 'testania_name',
    pusherTransportTLS = 'pusherTransportTLS',
    testaniaResponseStatus = 'testaniaResponseStatus',
}

const KEYS_TO_REMOVE = Object.values(LS_KEYS);

export const deleteConfig = () => {
    KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));

    localStorage.setItem('isResetStore', 'true');
};
