import UserApi from './components/User';
import PaymentApi from './components/Payment';

import config from '../config';

import ApiClient from './apiClient';

const TOKEN_KEY = 'token';

export default function () {
    const apiClient = new ApiClient({ apiUrl: config.API_URL });
    const user = new UserApi({ apiClient });
    const payment = new PaymentApi({ apiClient });

    window.addEventListener('storage', function (storageEvent) {
        if (TOKEN_KEY === storageEvent.key) {
            const { newValue: token } = storageEvent;

            if (token) {
                apiClient.private.setAuthToken(token);
                user.apiClient.private.setAuthToken(token);
                payment.apiClient.private.setAuthToken(token);
            }
        }
    });

    return {
        apiClient,
        user,
        payment,
    };
}
