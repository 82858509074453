import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { selectCountry } from 'redux/user/selectors';
import { selectPayment } from 'redux/payment/selectors';
import {
    changeExclusiveOfferState,
    setAllProductsFromFirstPayment,
    setCurrentProduct,
    setPageType,
    setPaymentDiscount,
} from 'redux/payment/actions';

import { PageType } from 'constants/analytics';

import { sendGTMPaymentPageEvent } from 'services/Analytics/trackers/GTMtrakers';

import { getDiscountForPayment, isShortPayment } from 'helpers/utils';
import getTimerTime from 'helpers/timer';
import { getExclusiveOfferProductByCountry } from 'helpers/payment/exclusiveOffer';

import { CurrentProduct } from 'types/payments/payments';
import { IPaymentFlow } from './types';

export interface IUsePaymentLogic {
    timerKey?: string;
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
    pageInfo: IPaymentFlow;
    isOffer: boolean;
}

export const usePaymentLogic = ({
    timerKey = 'PaymentScreenDefaultTimer',
    productsListWithDiscount,
    productsListWithoutDiscount,
    pageInfo,
    isOffer,
}: IUsePaymentLogic) => {
    const timerTime = getTimerTime({ key: timerKey });

    const discount = useRef(0);

    const productsWithAndWithoutDiscount = [...productsListWithDiscount, ...productsListWithoutDiscount];

    const pageType = isOffer ? PageType.Offer : PageType.Payment;

    useEffect(() => {
        if (!isOffer) dispatch(setAllProductsFromFirstPayment(productsWithAndWithoutDiscount));

        dispatch(setPageType(pageType));
        sendGTMPaymentPageEvent(pageType);

        return () => {
            dispatch(setPaymentDiscount(discount.current));
        };
    }, [isOffer]);

    const dispatch = useDispatch();

    const country = useSelector(selectCountry);

    const EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY = getExclusiveOfferProductByCountry(country);

    const { currentProduct, exclusiveOfferStatus: isExclusiveOfferModalOpen, isLoadingBankCard } = useSelector(
        selectPayment
    );

    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isTimerEnd, setIsTimerEnd] = useState(timerTime.seconds <= 0);
    const [isOpenCheckoutPopup, setIsOpenCheckoutPopup] = useState(false);
    const [isExclusiveOffer, setIsExclusiveOffer] = useState(false);
    const [currentProductForPayment, setCurrentProductForPayment] = useState<CurrentProduct>(
        (currentProduct as CurrentProduct) || pageInfo.products[0]
    );

    const products: CurrentProduct[] = isTimerEnd ? productsListWithoutDiscount : productsListWithDiscount;

    useEffect(() => {
        if (isExclusiveOfferModalOpen) {
            setPaymentModalOpen(false);
        }
    }, [isExclusiveOfferModalOpen]);

    useEffect(() => {
        products.forEach((item) => item.is_preselected && setPaymentPrice(item));
    }, []);

    useEffect(() => {
        if (Number(timerTime) <= 0) {
            const regularProduct = products.find(
                ({ period, trial }) =>
                    currentProductForPayment?.period === period && currentProductForPayment?.trial === trial
            );

            if (regularProduct) setPaymentPrice(regularProduct);
        }
    }, [timerTime]);

    const setPaymentPrice = (currentProduct: CurrentProduct) => {
        if (!isPaymentModalOpen) {
            discount.current = getDiscountForPayment(currentProduct);

            dispatch(setCurrentProduct(currentProduct));
            setCurrentProductForPayment(currentProduct);
        }
    };

    const paymentModalHandler = () => {
        setPaymentModalOpen(false);

        if (isExclusiveOffer) {
            dispatch(changeExclusiveOfferState(true));
        } else {
            const result = isShortPayment();

            if (result) {
                setIsOpenCheckoutPopup(true);
            }
        }
    };

    const openPaymentModal = () => {
        // @ts-ignore
        setCurrentProductForPayment(currentProduct);
        setPaymentModalOpen(true);
    };

    const onTimerEnd = () => {
        setIsTimerEnd(true);
        discount.current = 0;
    };

    const exclusiveOfferClick = () => {
        setIsExclusiveOffer(true);

        dispatch(setCurrentProduct(EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY));
        setCurrentProductForPayment(EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY);

        setPaymentModalOpen(true);
    };

    return {
        isExclusiveOfferModalOpen,
        isPaymentModalOpen,
        isTimerEnd,
        isOpenCheckoutPopup,
        exclusiveOfferClick,
        paymentModalHandler,
        openPaymentModal,
        setPaymentPrice,
        onTimerEnd,
        exclusiveOfferProduct: EXCLUSIVE_OFFER_PRODUCT_BY_COUNTRY,
        currentProductForPayment,
        timerTime,
        products,
        discount,
        isLoadingBankCard,
        setCurrentProductForPayment,
    };
};
