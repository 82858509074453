import { Image, Text } from 'wikr-core-components';
import { useState } from 'react';
import classNames from 'classnames/bind';

import { getTextCenter, getTextType } from './helpers';

import styles from './OptionDes.module.css';

import { ReactComponent as Checkmark } from './img/checkmark.svg';

import { IOptionDes, OptionSize } from '../types';

const cx = classNames.bind(styles);

const OptionDes = ({
    rightSvg: RightSvg,
    rightImage,
    isCheckbox = false,
    onClick,
    title,
    value,
    dataLocator,
    subtitle,
    mb = 0,
    size = OptionSize.Large,
    tiled,
}: IOptionDes) => {
    const [checked, setChecked] = useState(false);

    const optionClass = cx('option', {
        optionChecked: checked,
        optionWithRightImg: rightImage,
        [size]: size,
        tiled: tiled,
    });

    const textType = getTextType(size);
    const textCenter = getTextCenter(size);

    return (
        <div className={styles.optionWrapper} style={{ marginBottom: `${mb}px` }}>
            <input
                type="checkbox"
                data-locator={dataLocator}
                className={styles.input}
                value={value}
                onChange={({ target }) => {
                    setChecked(target.checked);
                    onClick && onClick(value, target.checked);
                }}
            />
            <div className={optionClass}>
                {isCheckbox && <div className={styles.checkbox}>{checked && <Checkmark />}</div>}

                <div className={styles.contentWrapper}>
                    <div>
                        <Text text={title} medium type={textType} center={textCenter} />
                        {subtitle && <Text text={subtitle} type="small-text" />}
                    </div>
                    {rightImage && <Image src={rightImage} alt="optionImg" maxWidth={110} />}
                    {RightSvg && <RightSvg />}
                </div>
            </div>
        </div>
    );
};

export { OptionDes };
