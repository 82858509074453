import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import useNetworkStatus from 'hooks/useNetworkStatus/useNetworkStatus';

import { Button, Image, Text } from 'libComponents';
import Modal from 'components/Modal';

import styles from './styles.module.css';

import globe_with_meridians from './img/globe_with_meridians.webp';

const NoConnectionModal = () => {
    const [isLoading, setIsLoading] = useState(false);

    const isOnline = useNetworkStatus();

    const { t } = useTranslation();

    const onClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen={!isOnline} withClose={false} isShortView>
            <Image center maxWidth={40} alt={t('basics.noInternet.title')} src={globe_with_meridians} mb={20} />
            <Text type="h5" text={t('basics.noInternet.title')} bold center mb={12} />
            <Text
                className={styles.errorSubtitle}
                color="text-secondary"
                text={t('basics.noInternet.subTitle')}
                center
                mb={32}
            />
            <Button isLoading={isLoading} text={t('basics.tryAgain')} onClick={onClick} />
        </Modal>
    );
};

export default NoConnectionModal;
