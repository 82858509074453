export const upsellRoutes = [
    {
        path: 'PaymentLandingPages/UpsellSub',
        route: 'upsell',
    },
    {
        path: 'PaymentLandingPages/Upsell2',
        route: 'upsell-2',
    },
    {
        path: 'PaymentLandingPages/UpsellSub',
        route: 'upsell-sub',
    },
    {
        path: 'PaymentLandingPages/UpsellGuides',
        route: 'upsell-one-time',
    },
];
