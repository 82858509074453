import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from 'constants/common';

import { sendAnalyticResetContactUsClick } from 'services/Analytics';

import { ContactUsLinkPropsType } from './types';

const ContactUsLink = ({ subject, mb, text }: ContactUsLinkPropsType) => {
    const { t } = useTranslation();

    return (
        <a
            data-locator="contactUsLinkBtn"
            href={`mailto:${SUPPORT_EMAIL}?subject=${subject}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={sendAnalyticResetContactUsClick}
        >
            <Text dataLocator="contactButton" type="overline" center bold text={t(text)} mb={mb} />
        </a>
    );
};

export default ContactUsLink;
