import { ReactElement } from 'react';
import classNames from 'classnames/bind';

import { Image, Text } from 'libComponents';

import styles from './CardComponentDes.module.css';

import { ReactComponent as CheckMark } from './img/Checkmark.svg';

import { ICardComponentDes } from './types';

const cx = classNames.bind(styles);

const CardComponentDes = ({
    dataLocator,
    onClick,
    img,
    title,
    buttonColor,
    withoutCheck,
}: ICardComponentDes): ReactElement => {
    const buttonStyles = cx('buttonBox', buttonColor);

    return (
        <div onClick={onClick} data-locator={dataLocator} className={styles.card}>
            <div className={styles.imageWrapper}>
                <Image src={img} alt="body" />
            </div>
            <div className={buttonStyles}>
                <Text type="small-button" color="text-main">
                    {title}
                </Text>
                {!withoutCheck && <CheckMark />}
            </div>
        </div>
    );
};

export default CardComponentDes;
