import { useState, useEffect } from 'react';

const useNetworkStatus = () => {
    const [isOnline, toggleNetworkStatus] = useState(window.navigator.onLine);

    useEffect(() => {
        const onNetworkStatusChange = () => toggleNetworkStatus((prev) => !prev);

        window.addEventListener('online', onNetworkStatusChange);
        window.addEventListener('offline', onNetworkStatusChange);

        return () => {
            window.removeEventListener('online', onNetworkStatusChange);
            window.removeEventListener('offline', onNetworkStatusChange);
        };
    }, []);

    return isOnline;
};

export default useNetworkStatus;
