import { CSS_CHUNK_LOAD_FAILED, chunkError, loadErrorMessages } from 'constants/lazyWithRetry';

export const isChunkError = (error: Error) => {
    if (!error?.message) {
        return {
            isChunkLoadError: false,
            isFailedToLoadSafari: false,
            isFailedToLoadChrome: false,
            hasError: false,
        };
    }

    const isFailedToLoadSafari = error.message.includes(loadErrorMessages.safariLoadError);
    const isFailedToLoadChrome = error.message.includes(loadErrorMessages.chromeLoadError);
    // @ts-ignore
    const isChunkLoadError = error.name === chunkError.chunkLoadError || error['code'] === CSS_CHUNK_LOAD_FAILED;

    return {
        isChunkLoadError,
        isFailedToLoadSafari,
        isFailedToLoadChrome,
        hasError: isFailedToLoadSafari || isFailedToLoadChrome || isChunkLoadError,
    };
};
