import { ReactElement } from 'react';

export enum PersonalFeedbackBgColor {
    DEFAULT = 'bgDefault',
    EASY = 'bgEasy',
    NORMAL = 'bgNormal',
    WARNING = 'bgWarning',
    OBESE = 'bgObese',
}

export interface IPersonalFeedbackBase {
    title: string;
    feedback: string;
    bgColor?: PersonalFeedbackBgColor;
    titleSlogan?: string;
    sloganColor?: string;
    className?: string;
    sloganTitleClassName?: string;
    titleClassName?: string;
}

export interface IPersonalFeedbackWithIcon extends IPersonalFeedbackBase {
    image?: string;
    icon: ReactElement;
}

export interface IPersonalFeedbackWithImg extends IPersonalFeedbackBase {
    image: string;
    icon?: ReactElement;
}

export type IPersonalFeedback = IPersonalFeedbackWithIcon | IPersonalFeedbackWithImg;
