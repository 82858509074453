import { IText } from 'wikr-core-components';
import classNames from 'classnames/bind';

import { Text } from 'libComponents';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

const TextDes = (props: IText) => {
    const classNames = cx('redesignedFont', props.className);

    return <Text {...props} className={classNames} />;
};

export default TextDes;
