import { useTranslation } from 'react-i18next';

import { DEFAULT_NAVIGATOR_LANGUAGE, AVAILABLE_LOCALIZATIONS } from 'constants/localization';

import { replaceUnderscoreWithSpace } from './utils';

export const getNavigatorLanguage = () => {
    let language;

    if (navigator?.languages?.length) {
        language = navigator.languages[0];
    } else {
        language = navigator?.language || DEFAULT_NAVIGATOR_LANGUAGE;
    }

    return language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    return verifyLanguage(navigatorLanguage);
};

export const getCurrentLocalization = () => {
    const routerLang = window.location.pathname.split('/')[1];

    return verifyLanguage(routerLang);
};

export const getConfigurableTitle = ({
    title = '',
    defaultKey = '',
    key,
    untranslatableKey,
    // remove it after all dynamic keys will be refactored
    dynamicFlag = false,
}: {
    title?: string;
    defaultKey?: string;
    key?: string;
    untranslatableKey?: string;
    dynamicFlag?: boolean;
}) => {
    const { t, i18n } = useTranslation();

    const getUntranslatableKey = untranslatableKey ? t(`${untranslatableKey}`, { value: title }) : title;

    const composedString = `${key}${title}${dynamicFlag ? '--dynamicKey' : ''}`;

    const translatedTitle = i18n.exists(composedString) ? composedString : getUntranslatableKey;

    return replaceUnderscoreWithSpace(t(title && title.trim() === '' ? defaultKey : translatedTitle));
};

export const verifyLanguage = (lang: string) => (AVAILABLE_LOCALIZATIONS.includes(lang) ? lang : null);
