export const onboardingScreensList = [
    'ob-welcome',
    'ob-first-step',
    'ob-amazing',
    'ob-smartwatch-feedback',
    'ob-daily-goal-feedback',
    'ob-current-body-type',
    'ob-target-body-type',
    'ob-back-knees-issues',
    'ob-benefits-harvard',
    'ob-benefits',
    'ob-fortunately',
    'ob-smartwatch-type',
    'ob-health',
    'ob-work',
    'ob-medication',
    'ob-goals',
    'ob-risk',
    'ob-body-type',
    'ob-body-type-target',
    'ob-bad-habits',
    'ob-fitness-level',
    'ob-fitness-walking',
    'ob-smartwatch',
    'ob-walking-activity',
    'ob-hiking',
    'ob-level',
    'ob-thankyou',
    'ob-health-feedback',
    'ob-default-activity',
    'ob-daily-goal',
    'ob-pace',
    'ob-gender',
    'ob-target-zones',
    'ob-body-image',
    'ob-activity-level',
    'ob-walking',
    'ob-reasons',
    'ob-pushups-squats',
    'ob-yoga-level',
    'ob-bending-knees',
    'ob-meals-feel',
    'ob-sleep',
    'ob-water',
    'ob-diets',
    'ob-height',
    'ob-weight',
    'ob-weight-target',
    'ob-special-activity',
    'ob-age',
    'ob-tags',
    'ob-tags-b',
    'ob-occasion',
    'ob-occasion-result',
    'ob-medications',
    'ob-pregnant',
    'ob-yoga-effect',
    'ob-target-zones-a',
    'ob-flexibility',
    'ob-unfortunately',
    'ob-restrictive-diet',
    'ob-goals-mix',
    'ob-body-type-mix',
    'ob-body-type-target-mix',
    'ob-target-zones-mix',
    'ob-pilates-level',
    'ob-familiarity-full',
    'ob-target-zones-full',
    'ob-current-body-type-a',
    'ob-target-body-type-a',
    'ob-target-zones-b',
    'ob-target-zones-c',
    'ob-yoga-level-plan-socproof',
    'ob-yoga-level-details-coach',
    'ob-goals-a',
    'ob-goals-b',
    'ob-goals-feedback-a',
    'ob-goals-feedback-b',
    'ob-sex',
    'ob-goals-meditation',
    'ob-current-emotions',
    'ob-positive',
    'ob-unfortunately-stress',
    'ob-stress-look',
    'ob-stress-time',
    'ob-sleep-problems',
    'ob-focus',
    'ob-selftime',
    'ob-general',
    'ob-general1',
    'ob-general2',
    'ob-general3',
    'ob-general4',
    'ob-general5',
    'ob-general6',
    'ob-general7',
    'ob-general8',
    'ob-general9',
    'ob-general10',
    'ob-sleep-full',
    'ob-see-intro',
    'ob-see-1',
    'ob-see-2',
    'ob-see-3',
    'ob-height-des',
    'ob-weight-des',
    'ob-weight-target-des',
    'ob-age-des',
    'ob-current-body-type-a-des',
    'ob-target-body-type-a-des',
    'ob-target-zones-c-des',
    'ob-goals-des',
    'ob-body-image-des',
    'ob-walking-des',
    'ob-bending-knees-des',
    'ob-familiarity-full-des',
    'ob-tags-a-des',
    'ob-activity-level-des',
    'ob-pilates-level-des',
    'ob-meals-feel-des',
    'ob-water-des',
    'ob-diets-des',
    'ob-occasion-des',
    'ob-occasion-result-des',
    'ob-pushups-squats-des',
    'ob-sleep-des',
    'ob-yoga-level-des',
    'ob-general-des',
    'ob-general-des1',
    'ob-general-des2',
    'ob-general-des3',
    'ob-general-des4',
    'ob-general-des5',
    'ob-health-conditions',
    'ob-back-issue-config',
    'ob-waterfall',
    'ob-crowded',
    'ob-suitable-trails',
    'ob-bad-weather',
    'ob-trail-difficulty',
    'ob-beforehand',
    'ob-get-lost',
    'ob-weekends',
    'ob-push',
    'ob-brain',
    'ob-stress-level',
    'ob-reasons-a',
    'ob-nature',
    'ob-hiking-a',
    'ob-trail-distance',
    'ob-person-type',
    'ob-fitness-level-a',
    'ob-tags-a',
    'ob-type-hiking',
    'ob-special-activity-a',
    'ob-feelings',
    'ob-challenges',
    'ob-injuries',
    'ob-socialize',
    'ob-busy',
    'ob-inner-power',
    'welcome-to-fottur',
    'ob-hiking-profile-summary',
    'ob-health-profile-summary',
    'ob-psychological-profile',
    'ob-hiking-profile',
    'ob-benefits-a',
    'ob-emotions',
    'ob-driven',
    'ob-walking-meditation',
    'ob-stress-reasons',
    'ob-habits',
    'ob-procrastination',
    'ob-sleep-issues',
    'ob-trauma-profile',
    'ob-trauma-experience',
    'ob-trauma-info',
    'ob-trauma-change',
    'ob-start-age',
];
