import { call, select, takeLatest } from 'redux-saga/effects';

import api from 'apiSingleton';

import { getCurrentProduct } from 'redux/sagas/payment/validatePayment';
import { selectSubscriptionId } from 'redux/payment/selectors';
import * as actionTypes from 'redux/payment/actionTypes';

import { PurchaseData } from 'services/Analytics/types';

import { isLocalhost, replaceDataWithLocalhost } from 'helpers/environment';

import { CurrentProduct } from 'types/payments/payments';

import { PaymentStore } from 'interfaces/Store/paymentStore';
import { collectPaymentAnalyticsSaga } from './collectPaymentAnalyticsSaga';

export function* skipTrialSubscription() {
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const { id, product_code, price } = currentProduct;

    try {
        const subscription_id: PaymentStore['subscriptionId'] = yield select(selectSubscriptionId);

        const analytic_data: PurchaseData = yield call(collectPaymentAnalyticsSaga, {
            currentProduct,
            oneClickPrice: currentProduct.price,
            screenId: 'skip-trial',
        });

        const meta = {
            price,
            product_id: id,
            product_code,
            subscription_id,
            analytic_data,
        };

        // fix of local problem with SSRF
        isLocalhost() && replaceDataWithLocalhost(meta);

        yield api.payment.replaceSubscription(meta);
    } catch (errorData) {
        if (errorData?.error) {
            console.error('error', errorData?.error);
        }
    }
}

export const skipTrial = [takeLatest(actionTypes.SKIP_TRIAL_SUBSCRIPTION, skipTrialSubscription)];
