export enum ConfigScreenColors {
    BLACK = 'black',
    GRAY = 'gray',
    WHITE = 'white',
}

export const DEFAULT_SCREEN_COLOR: ConfigScreenColors = ConfigScreenColors.BLACK;

export const COLOR_THEME_MAP: Record<ConfigScreenColors, 'text-main' | 'text-secondary' | 'surface-default'> = {
    [ConfigScreenColors.BLACK]: 'text-main',
    [ConfigScreenColors.GRAY]: 'text-secondary',
    [ConfigScreenColors.WHITE]: 'surface-default',
};
