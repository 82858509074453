import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';

import { selectHeaderSettings } from 'redux/redesignedFlow/selectors';

import { COLOR_THEME_MAP } from 'constants/redesignedFlow';
import { GET_MY_PLAN_BTN } from 'constants/payments';

import Logo from 'components/Logo';
import type { HeaderTypes } from 'components/Header/types';
import Button from 'components/Button';

import styles from './HeaderDes.module.css';

import { ReactComponent as SkipArrow } from 'assets/img/items/skipArrow.svg';
import { ReactComponent as BackIcon } from './img/icon-back.svg';

const cx = classNames.bind(styles);

const HeaderDes = ({
    title = GET_MY_PLAN_BTN,
    squareBtn = false,
    boldButton,
    history,
    getPlanClick,
    disabled,
    onSkipClick,
    isOnboardingHeader,
    progressBar: { currentScreen, totalScreens, width },
}: HeaderTypes) => {
    const { logoColor, isBackgroundOn } = useSelector(selectHeaderSettings);

    return (
        <header className={styles.header} style={{ ...(!isBackgroundOn && { backgroundColor: 'transparent' }) }}>
            <div className={styles.headerContainer}>
                <div className={styles.top}>
                    {isOnboardingHeader && (
                        <button type="button" className={styles.backButton} onClick={() => history.goBack()}>
                            <BackIcon />
                        </button>
                    )}
                    <Logo
                        isOnboarding
                        centered
                        onboardingLogo={isOnboardingHeader}
                        className={cx(COLOR_THEME_MAP[logoColor])}
                    />
                    {isOnboardingHeader && (
                        <div className={styles.pageCounter}>
                            <span className={styles.pageStart}>{currentScreen}</span>
                            <span className={styles.pageSeparator}>/</span>
                            <span className={styles.pageEnd}>{totalScreens}</span>
                        </div>
                    )}
                </div>
                {isOnboardingHeader && (
                    <div className={styles.progressBox}>
                        <div className={styles.progressBar} style={{ width }} />
                    </div>
                )}
                {getPlanClick && (
                    <Button
                        dataLocator="headerNavBtn"
                        onClick={getPlanClick}
                        title={title}
                        primary
                        rounded
                        small
                        squareBtn={squareBtn}
                        disabled={disabled}
                        bold={boldButton}
                    />
                )}
                {onSkipClick && (
                    <button onClick={onSkipClick} className={styles.skipButton}>
                        <Trans>skip</Trans>
                        <SkipArrow />
                    </button>
                )}
            </div>
        </header>
    );
};

export default HeaderDes;
