import { Button } from 'wikr-core-components';
import { FC, useState } from 'react';

import { OptionDes } from 'components/redesigned/OptionDes';

import style from './OptionBoxDes.module.css';

import { IOptionBoxDes } from './types';

const OptionBoxDes: FC<IOptionBoxDes> = ({ onClick, optionData, buttonData }) => {
    const [optionValues, setOptionValues] = useState<string[]>([]);

    const handleOptions = (value: string, checked: boolean) => {
        if (checked) {
            setOptionValues((prevOptions) => [...prevOptions, value]);
        } else {
            setOptionValues((prevOptions) => prevOptions.filter((option) => option !== value));
        }
    };

    return (
        <div className={style.optionBoxDes}>
            <div className={style.options}>
                {optionData?.map((props, index) => (
                    <OptionDes {...props} onClick={handleOptions} key={index} />
                ))}
            </div>
            <div className={buttonData?.fixed ? style.btnFixed : undefined}>
                <Button
                    dataLocator={buttonData?.dataLocator}
                    text={buttonData?.text}
                    disabled={optionValues.length <= 0}
                    onClick={() => {
                        onClick && onClick(optionValues);
                    }}
                />
            </div>
        </div>
    );
};

export { OptionBoxDes };
