import { IPaymentFlow } from './types';
import { CurrentProduct } from '../types/payments/payments';

export interface IUseProducts {
    pageInfo: IPaymentFlow;
}

interface IUseProductsReturn {
    productsListWithDiscount: CurrentProduct[];
    productsListWithoutDiscount: CurrentProduct[];
}

export function useProducts({ pageInfo }: IUseProducts): IUseProductsReturn {
    const setIsPreselectedValue = (list: CurrentProduct[], elementIndex: number) => {
        if (list[elementIndex]) list[elementIndex].is_preselected = true;
    };

    const getProductsList = () => {
        const products = pageInfo.products;

        const averageProductValue = products.length / 2;
        const elementIndex = products.findIndex((item) => item.is_preselected);

        const productsListWithDiscount = products.filter((product, i) => i < averageProductValue);
        const productsListWithoutDiscount = products.filter((product, i) => i >= averageProductValue);

        setIsPreselectedValue(productsListWithDiscount, elementIndex);
        setIsPreselectedValue(productsListWithoutDiscount, elementIndex);

        return { productsListWithDiscount, productsListWithoutDiscount };
    };

    return getProductsList();
}
