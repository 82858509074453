import classNames from 'classnames/bind';

import styles from './ModalClose.module.css';

const cx = classNames.bind(styles);

function ModalClose(props) {
    const { notificationModalIcon, isLeftClose, onClick, isFixed, isSmallClose } = props;

    const modalStyles = cx('closePopup', {
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
    });
    const handleClick = () => {
        onClick && onClick();
    };

    return <span className={modalStyles} onClick={handleClick} data-locator="closeCheckoutButton" />;
}

export default ModalClose;
