export const DEFAULT_USER_PAYLOAD = {
    email: 'test@gmail.com',
    gender: 'female',
    age: 23,
    fitlevel: 'medium',
    reasons: ['relieve_stress'],
    height: 124.46,
    default_activity_type: 'running',
    special: ['urban_exploring'],
    nutrition_preferences: 'omnivores',
    weight: 56,
    units: 'imperial',
    bmi_group: 'obese',
};
