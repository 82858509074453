import * as onboadringActions from 'redux/onboadring/actions';

import { parseMultiValuesString } from 'helpers/utils';

export const URL_PARAMS_MAPPING = {
    gender: {
        action: onboadringActions.setGender,
    },
    reasons: {
        action: onboadringActions.setReasons,
        modificator: parseMultiValuesString,
    },
    default_activity_type: {
        action: onboadringActions.setDefaultActivity,
    },
    fitlevel: {
        action: onboadringActions.setFitnessLvl,
    },
    bmi_group: {
        action: onboadringActions.setBmiGroup,
    },
    age: {
        action: onboadringActions.setAge,
        modificator: Number.parseInt,
    },
    health: {
        action: onboadringActions.setHealth,
    },
    nutrition_preferences: {
        action: onboadringActions.setNutritionPreferences,
    },
    special: {
        action: onboadringActions.setSpecialActivity,
        modificator: parseMultiValuesString,
    },
};
