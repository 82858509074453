import {
    getArrayFromObjectByFieldName,
    checkLtv,
    checkPreselectedField,
    checkProductPeriods,
    checkProductsDividedByTwo,
    checkValuePairsIdentityByFieldName,
} from './utils';

export const getIntroductoryFlowValidator = (products) => {
    const arrOfPeriods = getArrayFromObjectByFieldName(products, 'period');

    const uniquePeriods = [...new Set(arrOfPeriods)];

    return [
        {
            exec: checkProductsDividedByTwo,
            args: { products },
            message: 'PRODUCTS DIVIDED BY TWO',
        },
        {
            exec: checkValuePairsIdentityByFieldName,
            args: { products, fieldName: 'trial' },
            message: 'TRIAL',
        },
        {
            exec: checkPreselectedField,
            args: { products, withImportantOrder: true },
            message: 'PRESELECTED FIELD',
        },
        {
            exec: checkProductPeriods,
            args: { periodsArr: uniquePeriods },
            message: 'PERIOD',
        },
        { exec: checkLtv, args: { products }, message: 'LTV' },
    ];
};
