import { DEFAULT_TIME_TIMER } from 'constants/values';

import { GetDiffReturnType } from 'helpers/Date/types';

import { Dayjs } from 'dayjs';
import Date from './Date/Date';

const getTimerTime = (params: { key: string; time?: number }): GetDiffReturnType => {
    const key = params?.key ?? window.location.pathname;
    const time = params?.time ?? DEFAULT_TIME_TIMER;

    const dateFrom = Date.getTime();
    const dateTo = Date.addMinutes(time);

    const timerKey = key ? `timerTime-${key}` : 'timerTime';

    const timerKeyStorage = localStorage.getItem(timerKey);

    if (timerKeyStorage) {
        return getDifferent(dateFrom, timerKeyStorage);
    } else {
        localStorage.setItem(timerKey, dateTo.toString());

        return getDifferent(dateFrom, dateTo.toString());
    }
};

const getDifferent = (dateFrom: Dayjs, dateTo: Dayjs | string) => Date.getDiff(dateFrom, dateTo);

export default getTimerTime;
