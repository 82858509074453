// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/namespace
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import {
    selectPaymentConfigPaypal,
    selectPaymentConfigApplePay,
    selectPaymentConfigSandbox,
} from 'redux/payment/selectors';
import { setPaymentConfig } from 'redux/payment/actions';

import { getRelease } from 'helpers/utils';

const RELEASE_CONFIG = {
    paypal: true,
    applaPay: true,
    sandbox: false,
};

function ReleaseConfig(props) {
    const isRelease = getRelease();

    useEffect(() => {
        if (isRelease) {
            setConfig(RELEASE_CONFIG);
        } else {
            getFlags();
        }
    }, []);

    const parse = (param) => {
        try {
            return JSON.parse(param);
        } catch (error) {
            console.error(error);

            return param;
        }
    };

    const getFlags = () => {
        const url = new URLSearchParams(props.location.search);
        const config = {
            paypal: parse(url.get('paypal')),
            applaPay: parse(url.get('applepay')),
            sandbox: parse(url.get('sandbox')),
        };

        setConfig(deleteIsNullUndefined(config));
    };

    const setConfig = (config) => {
        props.setPaymentConfig(config);
    };

    const deleteIsNullUndefined = (config) => {
        for (let propName in config) {
            if (config[propName] === null || config[propName] === undefined) {
                delete config[propName];
            }
        }
        if (Object.keys(config).length !== 0) {
            return config;
        } else {
            return RELEASE_CONFIG;
        }
    };

    return <div />;
}

const mapStateToProps = (state) => {
    return {
        paypal: selectPaymentConfigPaypal(state),
        applaPay: selectPaymentConfigApplePay(state),
        sandbox: selectPaymentConfigSandbox(state),
    };
};

const mapDispatchToProps = {
    setPaymentConfig,
};

const ReleaseConfigWithRouter = withRouter(ReleaseConfig);

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseConfigWithRouter);
