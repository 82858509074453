import classNames from 'classnames/bind';

import { LoaderTypes } from 'components/Loader/types';

import styles from './Loader.module.css';

const cx = classNames.bind(styles);

const Loader = ({ isLoading, additionalClass }: LoaderTypes) => {
    const loaderStyles = cx('loader', { [additionalClass]: additionalClass });

    return isLoading ? <div className={loaderStyles} /> : null;
};

export default Loader;
