import { DEFAULT_FLOW_LINK } from 'constants/defaultFlows';

import { isObject, isString } from 'helpers/utils';

export const isLocalhost = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// fix of local problem with SSRF
export const replaceDataWithLocalhost = (data: any) => {
    Object.entries(data).map(([fieldName, fieldValue]) => {
        if (isString(fieldValue)) {
            if ((fieldValue as string).includes('localhost')) {
                data[fieldName] = DEFAULT_FLOW_LINK;
            }
        }

        if (isObject(fieldValue)) {
            replaceDataWithLocalhost(fieldValue);
        }
    });
};

export const isOnline = () => navigator.onLine;
