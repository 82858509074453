import ReactModal from 'react-modal';
import { useEffect } from 'react';
import classNames from 'classnames/bind';

import modalLightStyles from './ModalLight.module.css';
import modalStyles from './Modal.module.css';

import ModalClose from '../ModalClose/ModalClose';

Modal.defaultProps = {
    styles: {},
    isOpen: false,
    withClose: true,
    shouldCloseOnOverlayClick: true,
    onClose: () => {},
};

function Modal(props) {
    const {
        children,
        styles,
        isOpen,
        onClose,
        withClose,
        shouldCloseOnOverlayClick,
        isTransparency,
        isTransparency36,
        isLeftClose,
        isExclusiveOffer,
        paddingTop32,
        paddingTop40,
        paddingTop78,
        isSmallClose,
        noOverflow,
        isFixedClose,
        isFlex,
        isLightTheme,
        isPopover,
        withOpacity = false,
        isShowModal = false,
        paddingTop24,
        withoutDesktop,
        isSkipTrialModal = false,
        dataLocator,
        className,
        isShortView = false,
    } = props;

    useEffect(() => {
        ReactModal.setAppElement('#app');
    }, []);

    const handleClose = () => {
        if (onClose) onClose();
    };

    const classes = Object.assign(styles);
    const stylesForModal = isLightTheme ? modalLightStyles : modalStyles;

    const cx = classNames.bind(stylesForModal);
    const contentStyles = cx('ReactModal__Content', className, {
        ReactModal__Content__withTransparency: isTransparency,
        skipTrialModal: isSkipTrialModal,
        shortView: isShortView,
        ReactModal__Content__withTransparency36: isTransparency36,
        ReactModal__Content__exclusiveOffer: isExclusiveOffer,
        ReactModal__Content__paddingTop32: paddingTop32,
        ReactModal__Content__paddingTop40: paddingTop40,
        ReactModal__Content__paddingTop78: paddingTop78,
        ReactModal__Content__noOverflow: noOverflow,
        ReactModal__Content__flex: isFlex,
        ReactModal__Content__withPopover: isPopover,
        ReactModal__Content__desktop: !withoutDesktop,
        // TODO: prop paddingTop
        ReactModal__Content__paddingTop24: paddingTop24,
    });
    const overlayStyles = cx('ReactModal__Overlay', {
        ReactModal__Overlay__isClose: withOpacity && !isShowModal,
        ReactModal__Overlay__isOpen: withOpacity && isShowModal,
        ReactModal__Overlay__withTransparency: isTransparency,
    });

    const bodyStyles = cx({
        ReactModal__Body__isClose: withOpacity && !isShowModal,
        ReactModal__Body__isOpen: withOpacity && isShowModal,
    });

    return (
        <ReactModal
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={false}
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={classes}
            contentLabel="Modal"
            className={contentStyles}
            overlayClassName={overlayStyles}
            bodyOpenClassName={bodyStyles}
            data={{ locator: dataLocator }}
        >
            {onClose && withClose && (
                <ModalClose
                    onClick={handleClose}
                    isLeftClose={isLeftClose}
                    isSmallClose={isSmallClose}
                    isFixed={isFixedClose}
                />
            )}
            {children}
        </ReactModal>
    );
}

export default Modal;
