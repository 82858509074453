import { ISwitcher } from 'wikr-core-components/dist/components/Switcher';
import classNames from 'classnames/bind';

import { Switcher } from 'libComponents';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

const SwitcherDes = (props: ISwitcher) => {
    const classNames = cx(props.className, 'redesignedSwitcher');

    return <Switcher {...props} className={classNames} />;
};

export default SwitcherDes;
