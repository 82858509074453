import classNames from 'classnames/bind';

import { IPersonalFeedback } from 'components/PersonalFeedback/types';
import PersonalFeedback from 'components/PersonalFeedback';

import styles from './styles.module.css';

const cx = classNames.bind(styles);

const PersonalFeedbackDes = (props: IPersonalFeedback) => {
    return <PersonalFeedback {...props} className={cx('redesignedPersonalFeedback', props.className)} />;
};

export default PersonalFeedbackDes;
