// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { Redirect } from 'react-router-dom';

import lazyLoad from 'helpers/lazyWithRetry';

import allRoutes from '../routesMap';

const PreloadTestania = lazyLoad({
    importer: () => import(/* webpackChunkName: "[request]" */ 'pages/GeneralPages/PreloaderTestania'),
});

const pathWithRegularExpression = (path) => `/:language([a-z]{2})?/${path}`;

const generateRoute = (route, pathToFile) => {
    const PageComponent = lazyLoad({
        importer: () => import(/* webpackChunkName: "[request]" */ `../../pages/${pathToFile}`),
    });

    return {
        path: pathWithRegularExpression(route),
        key: route,
        render: (props) => <PageComponent key={pathToFile} {...props} />,
    };
};

const routes = [
    {
        path: pathWithRegularExpression(''),
        key: 'preload-testania',
        render: (props) => <PreloadTestania {...props} />,
        exact: true,
    },
];

for (const item of allRoutes) {
    const { route, path } = item;
    const payload = generateRoute(route, path);

    routes.push(payload);
}

routes.push({
    path: '*',
    key: 'redirect-public',
    render: () => <Redirect to="/en" />,
});

export default routes;
