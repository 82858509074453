import { Trans } from 'react-i18next';
import { useEffect } from 'react';
import classNames from 'classnames/bind';

import Loader from 'components/Loader';
import LazyImage from 'components/LazyImage';

import base from 'assets/styles/Common.module.css';
import styles from './Button.module.css';

import payPalIco from 'assets/img/paymentMethods/payPal.webp';
import { ReactComponent as Arrow } from './img/arrow.svg';

import { GlobalStylesTypes } from 'types/commonInterfaces';
import { ButtonProps } from './types';

const globalStyles: GlobalStylesTypes = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Button(props: ButtonProps) {
    const {
        globalClassNames = [],
        title,
        onClick = () => {},
        inverse,
        animation,
        animationFast,
        primary,
        rounded,
        small,
        paypalButton,
        disabled = false,
        isLoading = false,
        type = 'button',
        bold,
        arrowed,
        dataLocator,
        isOnePrice,
        squareBtn,
        notificationBtn,
        pulse,
        btnConsent,
        isFastEasy,
        femaleFast,
        maleFast,
        noMargin,
        exclusive,
        skip,
    } = props;

    const classes = globalClassNames.map((item) => ({
        [item]: globalStyles[item],
    }));
    const btnClass = cx(
        'default',
        {
            primary,
            animation,
            animationFast,
            inverse,
            rounded,
            small,
            paypalButton,
            bold,
            arrowedLeft: arrowed === 'left',
            arrowedRight: arrowed === 'right',
            isOnePrice: isOnePrice,
            squareBtn: squareBtn,
            notificationBtn: notificationBtn,
            pulse,
            btnConsent: btnConsent,
            isFastEasy: isFastEasy,
            femaleFast,
            maleFast,
            noMargin,
            exclusive,
            skip,
        },
        pulse,
        classes
    );

    useEffect(() => {
        if (type === 'submit') {
            const handleSubmit = (e: KeyboardEvent) => {
                if (e.code === 'Enter') handleClick();
            };

            window.addEventListener('keypress', handleSubmit);

            return () => {
                window.removeEventListener('keypress', handleSubmit);
            };
        }
    }, []);

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <button
            data-locator={dataLocator}
            type={type}
            disabled={disabled || isLoading}
            className={btnClass}
            onClick={handleClick}
        >
            <Trans>{title}</Trans>
            <Loader isLoading={isLoading} additionalClass={paypalButton ? 'payPal' : ''} />
            {arrowed && <Arrow className={styles.arrow} />}
            {paypalButton && (
                <div className={styles.imageWrapper}>
                    <LazyImage alt="Pay pal" src={payPalIco} />
                </div>
            )}
        </button>
    );
}

export default Button;
