import { RouteItem } from './types';

import { otherRoutes, onboardingRoutes, paymentRoutes, resultRoutes, startRoutes, upsellRoutes } from './routesMap';

const formatRoutesList = (routeList: RouteItem[]) => {
    return routeList.map((item: RouteItem) => ({ route: item.route }));
};

export const setRoutesList = () => {
    window.ROUTES_LIST = {
        onboardingPages: formatRoutesList(onboardingRoutes),
        paymentPages: formatRoutesList(paymentRoutes),
        startPages: formatRoutesList(startRoutes),
        upsellPages: formatRoutesList(upsellRoutes),
        resultPages: formatRoutesList(resultRoutes),
        otherPages: formatRoutesList(otherRoutes),
    };
};
