import { PRODUCT_CODES } from './payments';

export const EXCLUSIVE_OFFER_PRODUCT = {
    payment_type: 'subscription',
    id: 'aba9f6af-cfb9-4bc2-ac07-891ad7a7fe77',
    name: 'One-week exclusive offer + Three-months (new geo)',
    trial: 7,
    period: 90,
    price_per_state: 1,
    start_price_per_state: 1,
    is_preselected: false,
    product_state: 'per_day',
    ltv: 1500,
    product_code: PRODUCT_CODES.MAIN,
};

export const PRICE_BY_COUNTRY: Record<string, { price: number; start_price: number }> = {
    BR: {
        price: 495,
        start_price: 14800,
    },
    MX: {
        price: 1699,
        start_price: 51599,
    },
    CL: {
        price: 92500,
        start_price: 2788800,
    },
    CO: {
        price: 399900,
        start_price: 11900000,
    },
    PE: {
        price: 399,
        start_price: 10900,
    },
    ZA: {
        price: 1999,
        start_price: 55900,
    },
    TW: {
        price: 2999,
        start_price: 94900,
    },
    PH: {
        price: 4999,
        start_price: 165900,
    },
    default: {
        price: 99,
        start_price: 2999,
    },
};
