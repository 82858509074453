import { useEffect } from 'react';

export function useScrollLock(isOpen: boolean) {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('ReactModal__Body--checkoutIsOpened');
        } else {
            document.body.classList.remove('ReactModal__Body--checkoutIsOpened');
        }

        return () => document.body.classList.remove('ReactModal__Body--checkoutIsOpened');
    }, [isOpen]);
}
