import config from 'config';

import { stageLink, prodLink } from './constants';

export const getLink = () => {
    switch (config.ENV) {
        case 'stage':
            return stageLink;

        case 'prod':
            return prodLink;

        default:
            return;
    }
};
