import classNames from 'classnames/bind';

import type { LogoTypes } from 'components/Logo/types';

import styles from './Logo.module.css';

import { ReactComponent as LogoSmall } from './img/logoFotturSmall.svg';
import { ReactComponent as LogoDefault } from './img/logoFottur.svg';

const cx = classNames.bind(styles);

export default function Logo({ centered, white, isOnboarding, onboardingLogo, logoFooter, className }: LogoTypes) {
    const logoClass = cx('logo', {
        centered,
        white,
        isOnboarding,
    });
    const logoDefaultImgClass = cx('logoImgDefault', 'logoImgFastEasy', className, {
        logoImgOnboarding: isOnboarding && onboardingLogo,
        logoFooter: logoFooter,
    });

    return (
        <div className={logoClass}>
            {(isOnboarding && onboardingLogo) || logoFooter ? (
                <LogoSmall className={className} />
            ) : (
                <LogoDefault className={logoDefaultImgClass} />
            )}
        </div>
    );
}
