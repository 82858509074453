import { takeLatest, put, all } from 'redux-saga/effects';

import api from 'apiSingleton';

import * as actionTypes from 'redux/payment/actionTypes';
import { getMerchantData as getMerchantDataAction, setLoadingStatus, setMerchantData } from 'redux/payment/actions';

import { MerchantDataResponseType } from 'types/payments/paymentApiInterfaces';

export function* getMerchantDataSaga({}: ReturnType<typeof getMerchantDataAction>) {
    try {
        yield put(setLoadingStatus(true));

        const merchantData: MerchantDataResponseType = yield api.payment.getMerchantData({
            product_id: '0406b47e-252c-4e94-a8eb-33f9c05e19b9',
            product_code: 'app_full_access',
            price: 1999,
            country: 'UA',
            currency: 'USD',
            payment_method: 1,
        });

        yield all([
            put(
                setMerchantData({
                    merchant: merchantData?.merchant,
                    signature: merchantData?.signature,
                    paymentIntent: merchantData?.payment_intent,
                })
            ),
            put(setLoadingStatus(false)),
        ]);
    } catch (e) {
        throw e;
    }
}

export const getMerchantData = [takeLatest(actionTypes.GET_MERCHANT_DATA, getMerchantDataSaga)];
