export const resultRoutes = [
    {
        path: 'ResultPages/Result',
        route: 'result',
    },
    {
        path: 'ResultPages/ResultFull',
        route: 'result-full',
    },
    {
        path: 'ResultPages/ObResultMeditation',
        route: 'ob-result-meditation',
    },
    {
        path: 'ResultPages/ResultFullDes',
        route: 'result-full-des',
    },
    {
        path: 'ResultPages/ResultA',
        route: 'result-a',
    },
];
