export const LOAD_DELAY_MS = 1500;
export const MAX_RETRIES = 15;
export const DEFAULT_RETRIES = 0;

export const CHUNK_LOAD_FAILED = 'CHUNK_LOAD_FAILED';
export const CSS_CHUNK_LOAD_FAILED = 'CSS_CHUNK_LOAD_FAILED';
export const LAZY_LOAD_ERROR = 'LAZY_LOAD_ERROR';

export const chunkError = {
    loadingChunkError: 'Loading chunk',
    loadingCSSChunkError: 'Loading CSS chunk',
    chunkLoadError: 'ChunkLoadError',
};

export const loadErrorMessages = {
    safariLoadError: 'Importing a module script failed',
    chromeLoadError: 'Failed to fetch dynamically',
    cssLoadError: 'Unable to preload CSS',
};
