import classNames from 'classnames/bind';

import { GlobalStylesTypes } from 'types/commonInterfaces';

export default (...stylesArr: GlobalStylesTypes[]) => {
    const globalStyles = stylesArr.reduce((styles, styleObj) => ({ ...styles, ...styleObj }), {});

    return {
        cx: classNames.bind(globalStyles),
        globalStyles,
    };
};
