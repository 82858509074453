export const DEFAULT_FLOW_PROJECT_NAME = 'trails';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;
export const DEFAULT_FLOW_LINK = 'https://app.trails-main-fe-stage.asqq.io/';

export const DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'trails__general__go3qka_1__Control',
    start_page: {
        id: 'start_age',
        name: 'start_age',
        config: {},
    },
    onboarding_flow: [
        {
            id: 'ob_gender',
            name: 'gender',
            config: {},
        },
        {
            id: 'ob_social_proof',
            name: 'ob_social_proof',
            config: {},
        },
        {
            id: 'ob_welcome',
            name: 'ob_welcome',
            config: {},
        },
        {
            id: 'ob_reasons',
            name: 'ob_reasons',
            config: {},
        },
        {
            id: 'ob_first_step',
            name: 'ob_first_step',
            config: {},
        },
        {
            id: 'ob_current_body_type',
            name: 'ob_current_body_type',
            config: {},
        },
        {
            id: 'ob_target_body_type',
            name: 'ob_target_body_type',
            config: {},
        },
        {
            id: 'ob_target_zones',
            name: 'ob_target_zones',
            config: {},
        },
        {
            id: 'ob_amazing',
            name: 'ob_amazing',
            config: {},
        },
        {
            id: 'ob_fitness_level',
            name: 'ob_fitness_level',
            config: {},
        },
        {
            id: 'ob_tags',
            name: 'ob_tags',
            config: {},
        },
        {
            id: 'ob_fitness_walking',
            name: 'ob_fitness_walking',
            config: {},
        },
        {
            id: 'ob_walking_activity',
            name: 'ob_walking_activity',
            config: {},
        },
        {
            id: 'ob_hiking',
            name: 'ob_hiking',
            config: {},
        },
        {
            id: 'ob_benefits',
            name: 'ob_benefits',
            config: {},
        },
        {
            id: 'ob_benefits_harvard',
            name: 'ob_benefits_harvard',
            config: {},
        },
        {
            id: 'ob_default_activity',
            name: 'ob_default_activity',
            config: {},
        },
        {
            id: 'ob_special_activity',
            name: 'ob_special_activity',
            config: {},
        },
        {
            id: 'ob_daily_goal',
            name: 'ob_daily_goal',
            config: {},
        },
        {
            id: 'ob_daily_goal_feedback',
            name: 'ob_daily_goal_feedback',
            config: {},
        },
        {
            id: 'ob_pace',
            name: 'ob_pace',
            config: {},
        },
        {
            id: 'ob_level',
            name: 'ob_level',
            config: {},
        },
        {
            id: 'ob_smartwatch',
            name: 'ob_smartwatch',
            config: {},
        },
        {
            id: 'ob_work',
            name: 'ob_work',
            config: {},
        },
        {
            id: 'ob_sleep',
            name: 'ob_sleep',
            config: {},
        },
        {
            id: 'ob_water',
            name: 'ob_water',
            config: {},
        },
        {
            id: 'ob_meals_feel',
            name: 'ob_meals_feel',
            config: {},
        },
        {
            id: 'ob_diets',
            name: 'ob_diets',
            config: {},
        },
        {
            id: 'ob_bad_habits',
            name: 'ob_bad_habits',
            config: {},
        },
        {
            id: 'ob_fortunately',
            name: 'ob_fortunately',
            config: {},
        },
        {
            id: 'ob_back_knees_issues',
            name: 'ob_back_knees_issues',
            config: {},
        },
        {
            id: 'ob_health',
            name: 'ob_health',
            config: {},
        },
        {
            id: 'ob_medication',
            name: 'ob_medication',
            config: {},
        },
        {
            id: 'ob_health_loader',
            name: 'ob_health_loader',
            config: {},
        },
        {
            id: 'ob_health_feedback',
            name: 'ob_health_feedback',
            config: {},
        },
        {
            id: 'ob_unfortunately',
            name: 'ob_unfortunately',
            config: {},
        },
        {
            id: 'ob_thankyou',
            name: 'ob_thankyou',
            config: {},
        },
        {
            id: 'ob_height',
            name: 'ob_height',
            config: {},
        },
        {
            id: 'ob_weight',
            name: 'ob_weight',
            config: {},
        },
        {
            id: 'ob_weight_target',
            name: 'ob_weight_target',
            config: {},
        },
        {
            id: 'ob_age',
            name: 'ob_age',
            config: {},
        },
        {
            id: 'ob_feedback_summary',
            name: 'ob_feedback_summary',
            config: {},
        },
        {
            id: 'ob_risk',
            name: 'ob_risk',
            config: {},
        },
        {
            id: 'ob_occasion',
            name: 'ob_occasion',
            config: {},
        },
        {
            id: 'creating',
            name: 'creating',
            config: {},
        },
        {
            id: 'email',
            name: 'email',
            config: {},
        },
        {
            id: 'email_consent',
            name: 'email_consent',
            config: {},
        },
        {
            id: 'result',
            name: 'result',
            config: {},
        },
    ],
    payment_flow: [
        {
            id: 'payment_Aa_checkout_Alpha1',
            name: 'payment_Aa_checkout_Alpha1',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '7a83d865-4be2-41e1-92d6-072145e50d9e',
                    name: 'One-week intro (6.93) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 99,
                    start_price: 3895,
                    price: 693,
                    sign: '$',
                    code: 'USD',
                    ltv: 5409,
                },
                {
                    payment_type: 'subscription',
                    id: 'd8939b06-6648-46c7-86fe-0c5df60ed569',
                    name: 'One-months intro (15.19) - One-months (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 49,
                    start_price: 3895,
                    price: 1519,
                    sign: '$',
                    code: 'USD',
                    ltv: 3200,
                },
                {
                    payment_type: 'subscription',
                    id: '6e0bbd02-ee0b-4c93-a86f-7181f6ec2f51',
                    name: 'Three-months intro (25.99) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 29,
                    start_price: 6665,
                    price: 2599,
                    sign: '$',
                    code: 'USD',
                    ltv: 5101,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 3895,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 6171,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-months intro (38.95) - One-months (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 7111,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-months intro (66.65) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 7956,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_one_time',
            name: 'upsell_one_time',
            parent_id: 'payment_Aa_checkout_Alpha1',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan',
                    name: 'guide_meal_plan',
                    trial: 0,
                    period: 1,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
        {
            id: 'offer_Aa_checkout_Alpha1',
            name: 'offer_Aa_checkout_Alpha1',
            parent_id: 'payment_Aa_checkout_Alpha1',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '9a0f3d55-3279-4563-8c76-11520a1ea9eb',
                    name: 'One-week intro (5.15) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 254,
                    price_per_state: 74,
                    start_price: 3895,
                    price: 515,
                    sign: '$',
                    code: 'USD',
                    ltv: 5284,
                },
                {
                    payment_type: 'subscription',
                    id: '2efa5e56-620d-4c77-b691-8547ce397960',
                    name: 'One-months intro (11.29) - One-months (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: true,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 126,
                    price_per_state: 36,
                    start_price: 3895,
                    price: 1129,
                    sign: '$',
                    code: 'USD',
                    ltv: 5169,
                },
                {
                    payment_type: 'subscription',
                    id: '4884b8ac-b541-4bdc-94c7-139521f63ac6',
                    name: 'Three-months intro (19.29) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 74,
                    price_per_state: 21,
                    start_price: 6665,
                    price: 1929,
                    sign: '$',
                    code: 'USD',
                    ltv: 4630,
                },
                {
                    payment_type: 'subscription',
                    id: '743ec4d9-802f-4326-9907-d00a5d43ea1b',
                    name: 'One-week intro (17.77) - One-months (38.95)',
                    trial: 7,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 254,
                    start_price: 3895,
                    price: 1777,
                    sign: '$',
                    code: 'USD',
                    ltv: 6171,
                },
                {
                    payment_type: 'subscription',
                    id: '23e69cc7-d368-47b0-ad0b-42bf3c65735e',
                    name: 'One-months intro (38.95) - One-months (38.95)',
                    trial: 31,
                    period: 31,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 126,
                    start_price: 3895,
                    price: 3895,
                    sign: '$',
                    code: 'USD',
                    ltv: 7111,
                },
                {
                    payment_type: 'subscription',
                    id: 'a51e5fc3-aa3b-43eb-91e3-3b9c559d4666',
                    name: 'Three-months intro (66.65) - Three-months (66.65)',
                    trial: 90,
                    period: 90,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 74,
                    start_price: 6665,
                    price: 6665,
                    sign: '$',
                    code: 'USD',
                    ltv: 7956,
                },
            ],
            config: {},
        },
        {
            id: 'upsell_one_time',
            name: 'upsell_one_time',
            parent_id: 'offer_Aa_checkout_Alpha1',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'lifetime',
                    id: 'guide_meal_plan',
                    name: 'guide_meal_plan',
                    trial: 0,
                    period: 1,
                    product_code: 'app_full_access',
                    is_preselected: false,
                    product_state: 'per_day',
                    processor: 'solid',
                    location: 'PL',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    start_price: 999,
                    price: 999,
                    sign: '$',
                    code: 'USD',
                    ltv: 701,
                },
            ],
            config: {},
        },
    ],
    expire: 1699440731316,
};
