import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { selectMeasureSystem } from 'redux/onboadring/selectors';
import { setMeasure } from 'redux/onboadring/actions';

import { MEASURE_SYSTEM } from 'constants/measureUnits';

import { MeasureSystemType } from 'types/commonInterfaces';
import { UseMeasureUnitReturn } from './types';

export function useMeasureUnit(): UseMeasureUnitReturn {
    const measureSystem: MeasureSystemType = useSelector(selectMeasureSystem);
    const dispatch = useDispatch();

    const [measureSystemValue, setMeasureSystemValue] = useState(measureSystem);

    const setMeasureSystem = (unit: MeasureSystemType) => {
        dispatch(setMeasure(unit));

        setMeasureSystemValue(unit);
    };

    const getMeasureSystem = (unit: string) => MEASURE_SYSTEM[unit];

    return {
        measureSystem: measureSystemValue,
        setMeasureSystem,
        getMeasureSystem,
    };
}
