import * as actionTypes from './actionTypes';

import { RedesignedFlowI } from 'types/redesignedFlow';

export function setIsRedesignedFlow(status: boolean) {
    return {
        type: actionTypes.SET_IS_REDESIGNED_FLOW,
        payload: status,
    } as const;
}
export function setHeaderSettings(payload: RedesignedFlowI['headerSettings']) {
    return {
        type: actionTypes.SET_HEADER_SETTINGS,
        payload,
    } as const;
}
