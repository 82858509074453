import { selectTestaniaName } from 'redux/testania/selectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { webPushflow } from './constants';

import { getLink } from './helpers';

export function useConnectWebPush() {
    const abTestName = useSelector(selectTestaniaName);

    const link = getLink();

    useEffect(() => {
        if (!abTestName?.includes(webPushflow) || !link) return;

        const script = document.createElement('script');

        script.innerHTML = `
      (function(i,s,o,g,r,a,m){
        i["esSdk"] = r;
        i[r] = i[r] || function() {
          (i[r].q = i[r].q || []).push(arguments)
        }, a=s.createElement(o), m=s.getElementsByTagName(o)[0];
        a.async=1; a.src=g;
        m.parentNode.insertBefore(a,m)
      })(window, document, "script", "${link}", "es");
      es("pushOn");
    `;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
}
