import { useDispatch } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';

import { skipOnboarding } from 'redux/onboadring/actions';

import { isProd } from 'helpers/utils';

const useSkipOnboarding = () => {
    const dispatch = useDispatch();

    const handleSkipOnboarding = () => {
        dispatch(
            skipOnboarding({
                reload: () => window.location.reload(),
            })
        );
    };

    if (!isProd()) {
        useHotkeys('shift+o+b', handleSkipOnboarding);
    }
};

export default useSkipOnboarding;
